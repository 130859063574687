import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.4_patch_hash=asvfln47zwsvhcoi67rii3dfia_@babel+core@7.22.10_babel-plugin-macros@3.1_hra4vwj6dym5vlxwz3nyzub23e/node_modules/next/dist/client/components/render-from-template-context.js");
